<script setup>
import { useGlobalStore } from "@/stores/global"
import { computed } from "vue"

const props = defineProps({
  profileType: {
    type: String,
    required: true,
  },
})

defineEmits(["change"])

const store = useGlobalStore()

const profileUi = computed({
  get() {
    return props.profileType === "attacker"
      ? store.profileUiAttacker
      : store.profileUiDefender
  },
  set(v) {
    store.setProfileUi({
      profileType: props.profileType,
      profileUiType: v,
    })
  },
})
</script>

<template>
  <div class="profile-ui-selector">
    <div class="profile-ui-selector__label text-caption mr-2">Input:</div>
    <v-chip-group
      v-model="profileUi"
      mandatory
      selected-class="selected"
      @update:model-value="$emit('change', profileUi)"
    >
      <v-chip
        value="adhoc"
        :data-cy="`${profileType}-profile-ui--adhoc`"
        label
        @click.stop
      >
        Ad hoc
      </v-chip>
      <v-chip
        value="profile"
        :data-cy="`${profileType}-profile-ui--profile`"
        label
        @click.stop
      >
        Profile
      </v-chip>
    </v-chip-group>
  </div>
</template>

<style lang="scss" scoped>
.profile-ui-selector {
  width: auto;
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-right: -4px;
}
</style>
