<script setup>
import ProfileUiSelector from "@/components/Common/ProfileUiSelector"
import { useGlobalStore } from "@/stores/global"
import Loading from "@/components/Common/Loading"
import { computed, defineAsyncComponent } from "vue"
import { roleColour } from "~/utils/roleColour"
import { Icon } from "@iconify/vue"

const AdHocUiDefender = defineAsyncComponent({
  loader: () => import("@/components/OneVsOne/ProfileUi/AdHoc/Defender.vue"),
  loadingComponent: Loading,
})
const ProfileUiDefender = defineAsyncComponent({
  loader: () =>
    import("@/components/OneVsOne/ProfileUi/Profile/ProfileUiDefender.vue"),
  loadingComponent: Loading,
})

const store = useGlobalStore()

const adHocConfirmed = computed(() => {
  return (
    store.profileUiDefender === "adhoc" && store.currentDefenderAdHoc.confirmed
  )
})

const currentDefender = computed({
  get() {
    return store.currentDefender
  },
  set(obj) {
    store.setCurrentDefender(obj)
  },
})

const panelLabel = computed(() => {
  const label = "Defender"
  if (currentDefender.value?.name && store.profileUiDefender === "profile") {
    return `${label}: ${currentDefender.value.name}`
  }
  if (store.profileUiDefender === "adhoc") {
    return `${label}: Ad hoc`
  }
  return label
})

const tab = computed(() => {
  if (store.profileUiDefender === "profile") {
    return 1
  }
  return 0
})

const pointsComputed = computed(() => {
  if (
    store.profileUiDefender === "adhoc" &&
    !store.currentDefenderAdHoc.confirmed
  ) {
    return null
  }
  if (store.getDefenderForSim?.computed?.points) {
    return `${store.getDefenderForSim.computed.points}pts`
  } else {
    return null
  }
})
</script>

<template>
  <v-expansion-panel class="expansion-panel-defender">
    <v-expansion-panel-title data-cy="btn-toggle-exp-panel-defender">
      <div class="header-inner">
        <div class="header-icon">
          <Icon
            icon="mdi:security"
            height="22"
            :color="roleColour('Defender')"
          />
        </div>
        <div class="header-label" data-cy="btn-toggle-exp-panel-defender-label">
          {{ panelLabel }}
          <Icon
            v-if="adHocConfirmed"
            icon="mdi:check"
            :inline="true"
            class="ml-1"
          />
        </div>
        <div
          v-if="pointsComputed"
          class="header-points"
          data-cy="btn-toggle-exp-panel-defender-points"
        >
          {{ pointsComputed }}
        </div>
      </div>
    </v-expansion-panel-title>
    <v-expansion-panel-text>
      <div class="top-ui mb-4">
        <ProfileUiSelector profile-type="defender" />
      </div>
      <v-window v-model="tab">
        <v-window-item>
          <AdHocUiDefender class="pt-1" />
        </v-window-item>
        <v-window-item>
          <ProfileUiDefender class="pt-1" />
        </v-window-item>
      </v-window>
    </v-expansion-panel-text>
  </v-expansion-panel>
</template>

<style lang="scss" scoped>
.header-inner {
  display: flex;
  align-items: center;
  width: 100%;
}
.header-icon {
  flex: 0 1 auto;
  margin-right: 24px;
}
.header-label {
  flex: 1 1 auto;
}
.header-points {
  flex: 0 1 auto;
  margin: 0 24px;
}
</style>
