<script setup>
import ProfileUiSelector from "@/components/Common/ProfileUiSelector"
import { useGlobalStore } from "@/stores/global"
import Loading from "@/components/Common/Loading"
import { computed, defineAsyncComponent } from "vue"
import IconRaygun from "@/assets/images/vector/icon-raygun.svg"
import { roleColour } from "~/utils/roleColour"
import { Icon } from "@iconify/vue"

const AttackTypeSelector = defineAsyncComponent(
  () => import("@/components/Common/AttackTypeSelector.vue")
)
const AdHocUiAttacker = defineAsyncComponent({
  loader: () => import("@/components/OneVsOne/ProfileUi/AdHoc/Attacker.vue"),
  loadingComponent: Loading,
})

const ProfileUiAttacker = defineAsyncComponent({
  loader: () =>
    import("@/components/OneVsOne/ProfileUi/Profile/ProfileUiAttacker.vue"),
  loadingComponent: Loading,
})

const store = useGlobalStore()

const adHocConfirmed = computed(() => {
  return (
    store.profileUiAttacker === "adhoc" && store.currentAttackerAdHoc.confirmed
  )
})

const attacker = computed({
  get() {
    if (store.currentAttackerCompatibleWithAttackType) {
      return store.currentAttacker
    } else {
      return ""
    }
  },
  set(obj) {
    store.setCurrentAttacker(obj)
  },
})

const eagerPanelContent = computed(() => {
  // Returns true if a current attacker profile has been set.
  return typeof attacker.value.id !== "undefined"
})

const panelLabel = computed(() => {
  const label = "Attacker"
  if (
    attacker.value?.name &&
    store.currentAttackerCompatibleWithAttackType &&
    store.profileUiAttacker === "profile"
  ) {
    return `${label}: ${attacker.value.name}`
  }
  if (store.profileUiAttacker === "adhoc") {
    return `${label}: Ad hoc`
  }
  return label
})

const tab = computed(() => {
  if (store.profileUiAttacker === "profile") {
    return 1
  }
  return 0
})

const pointsComputed = computed(() => {
  if (
    store.profileUiAttacker === "adhoc" &&
    !store.currentAttackerAdHoc.confirmed
  ) {
    return null
  }
  if (store.getAttackerForSim?.computed?.points) {
    return `${store.getAttackerForSim.computed.points}pts`
  } else {
    return null
  }
})
</script>

<template>
  <v-expansion-panel class="expansion-panel-attacker">
    <v-expansion-panel-title data-cy="btn-toggle-exp-panel-attacker">
      <div class="header-inner">
        <div class="header-icon">
          <Icon
            v-if="store.profileUiAttacker === 'adhoc'"
            icon="mdi:flash"
            height="22"
            :color="roleColour('Attacker')"
          />
          <template v-else>
            <IconRaygun
              v-if="store.attackContext.attackType === 'Ranged'"
              class="icon-raygun"
            />
            <Icon
              v-else
              icon="mdi:sword"
              height="22"
              :color="roleColour('Attacker: Melee')"
            />
          </template>
        </div>
        <div class="header-label" data-cy="btn-toggle-exp-panel-attacker-label">
          {{ panelLabel }}
          <Icon
            v-if="adHocConfirmed"
            icon="mdi:check"
            :inline="true"
            class="ml-1"
          />
        </div>
        <div
          v-if="pointsComputed"
          class="header-points"
          data-cy="btn-toggle-exp-panel-attacker-points"
        >
          {{ pointsComputed }}
        </div>
      </div>
    </v-expansion-panel-title>

    <v-expansion-panel-text :eager="eagerPanelContent">
      <div class="top-ui mb-4">
        <ProfileUiSelector profile-type="attacker" />
        <AttackTypeSelector v-if="store.profileUiAttacker === 'profile'" />
      </div>

      <v-window v-model="tab">
        <v-window-item>
          <AdHocUiAttacker class="pt-1" />
        </v-window-item>
        <v-window-item>
          <ProfileUiAttacker class="pt-1" />
        </v-window-item>
      </v-window>
    </v-expansion-panel-text>
  </v-expansion-panel>
</template>

<style lang="scss" scoped>
.header-inner {
  display: flex;
  align-items: center;
  width: 100%;
}
.header-icon {
  flex: 0 1 auto;
  margin-right: 24px;
}
.header-label {
  flex: 1 1 auto;
}
.header-points {
  flex: 0 1 auto;
  margin: 0 24px;
}

.icon-raygun {
  color: #66bb6a; // Equivalent of: "green lighten-1" in vuetify speak.
  width: 22.5px;
  height: 22.5px;
}
@media (min-width: 35rem) {
  .top-ui {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}
</style>
